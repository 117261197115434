$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$font-size-base: .8125rem; // if browser base is 16px, then this is 13px
$font-size-sm: ($font-size-base * .923);
$font-size-md: ($font-size-base * 1.075);
$font-size-lg: ($font-size-base * 1.23);
$small-font-size: 85%;
$h1-font-size: $font-size-base * 1.845;
$h2-font-size: $font-size-base * 1.8;
$h3-font-size: $font-size-base * 1.6;
$h4-font-size: $font-size-lg;
$h5-font-size: $font-size-md;
$h6-font-size: $font-size-base;

$font-weight-light: 400;
$font-weight-normal : 400;
$font-weight-bold: 500;

$grid-gutter-width: 30px;
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 2),
    5: ($spacer * 3),
    6: ($spacer * 3.25),
    7: ($spacer * 4.375),
    8: ($spacer * 5),
  ),
  $spacers
);

$border-width: 1px;

$content-padding-x: 8px;
$content-padding-x-lg: 32px;
$table-padding-x-md: 24px;
$table-padding-y: 12px;
$table-header-padding-y: 16px;
$section-gap: 70px;
$text-icon-padding: 14px;
$border-radius: 0.15rem;

@mixin mixin-content-padding-x() {
  padding-left: $content-padding-x;
  padding-right: $content-padding-x;

  @media (min-width: map-get($grid-breakpoints, "lg")) {
    padding-left: $content-padding-x-lg;
    padding-right: $content-padding-x-lg;
  }
}

@mixin mixin-content-padding($add-x: 0, $add-y: 0, $add-x-lg: 0, $add-y-lg: 0) {
  padding: $content-padding-x + $add-y $content-padding-x + $add-x;

  @media (min-width: map-get($grid-breakpoints, "lg")) {
    padding: $content-padding-x-lg + $add-y-lg $content-padding-x-lg + $add-x-lg;
  }
}