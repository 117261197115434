@import 'src/style/variables';

.Loading {
  font-size: 1.25rem;
  .icon {
    color: $blue-soft;
  }
  .text {
    display: inline-block;
    text-align: left;
    width: 120px;
    padding-left: 10px;
  }
}