@import 'sizes';
@import 'colors';
@import 'mixins';

// Pagination
$pagination-padding-y : .5rem !default;
$pagination-padding-x : .75rem !default;
$pagination-padding-y-sm : .5rem !default;
$pagination-padding-x-sm : .8rem !default;
$pagination-padding-y-lg : .75rem !default;
$pagination-padding-x-lg : 1.5rem !default;
$pagination-line-height : 1.25 !default;
$pagination-color : $gray;
$pagination-bg : $gray-700;
$pagination-border-width : 0;
$pagination-border-color : $black;
$pagination-focus-box-shadow : 0;
$pagination-focus-outline : 0;
$pagination-hover-color : $white;
$pagination-hover-bg : $gray-600;
$pagination-hover-border-color : $black;
$pagination-active-color : $white;
$pagination-active-bg : $pagination-hover-bg;
$pagination-active-border-color : $pagination-hover-border-color;
$pagination-disabled-color : $pagination-color;
$pagination-disabled-bg : $pagination-bg;
$pagination-disabled-border-color : $black;
