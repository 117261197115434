@import 'src/style/variables';

.ItemsTable {
  .ItemsTable-top {
    min-height: 35px;
  }
  .negative {
    color: $red;
  }
  
  .positive {
    color: $green;
  }
  
  .expand {
    display: flex;
    justify-content: center;
    cursor: 'pointer';
    padding: 0;
    text-align: center;
    user-select: none;

    .arrow {
      border-radius: $border-radius;
      background-color: $gray-800;
      padding: 0 6px;
    }
  }
  
  .rt-tr.expandable {
    cursor: pointer;
  }
  .rt-tbody .rt-tr.expanded,
  .rt-tbody .rt-tr:hover {
    background-color: $table-hover-bg;
  }
  .rt-tbody .rt-tr.-padRow,
  .rt-tbody .rt-tr.-padRow:hover {
    background-color: transparent;
    cursor: default;
  }
  
  .ItemsTable-pageSizes {
    color: $gray;
    .Dropdown-control {
      min-width: 88px;
    }
  }
}