.LoadingWithBG {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  .loading-bg {
    position: absolute;
    background-color: #000;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    z-index: 101;
  }
  .loading-fg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 102;
  }
}