@import 'src/style/variables';
.Button {
  &.btn {
    font-size: $font-size-base * 1.15;
    border: none;
  }
  &.btn-size-lg {
    padding: 0.9rem 1.7rem;
  }
  &.btn-size-sm {
    padding: $content-padding-x (2 * $content-padding-x);
    font-size: $font-size-base;
  }
  &.btn-thin {
    padding-top: $content-padding-x / 4;
    padding-bottom: $content-padding-x / 4;
  }
  &.btn-wide {
    padding-left: 4 * $content-padding-x;
    padding-right: 4 * $content-padding-x;
  }
  &.btn-dark-2:hover {
    background-color: #202020;
  }
  &.btn-link {
    &:disabled {
      color: $gray;
      text-decoration: none;
      background-color: transparent;
      opacity: 1;
    }
  }
  &.btn-blue-hard {
    background-color: #1663cc;
    color: $white;

    &.btn-hover-border {
      border: 1px solid transparent;
      &:hover {
        border: 1px solid $white;
      }
    }
  }
}