@import '../../node_modules/bootstrap/scss/functions';
@import 'variables';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
// custom vars

@each $color, $value in $colors {
  @include bg-variant(".bg-#{$color}", $value);
}

$btn-font-weight: $font-weight-bold;
$input-btn-focus-box-shadow: 0;
$font-family-sans-serif: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace: 'Roboto Mono', SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$link-color: $blue-soft;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;

$input-bg: $gray-700;
$input-disabled-bg: $gray-800;
$input-color: $body-color;
$input-border-color: $border-color;

// Tables
$table-cell-padding : $table-padding-y $content-padding-x;
$table-cell-padding-sm : .3rem !default;
$table-border-width : $border-width !default;
$table-border-color : $border-color !default;

// Navbar
$navbar-dark-color:                 $gray;
$navbar-dark-hover-color:           $white;
$navbar-dark-active-color:          $gray-400;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg:       str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-dark-toggler-border-color:  $border-color;
$navbar-toggler-padding-y:          .5rem;
$navbar-toggler-padding-x:          .75rem;
$navbar-toggler-font-size:          $font-size-lg;

$nav-tabs-border-color:             $border-color;
$nav-tabs-border-width:             $border-width;
$nav-tabs-border-radius:            0;
$nav-tabs-link-hover-border-color:  $border-color;
$nav-tabs-link-active-color:        $white;
$nav-tabs-link-active-bg:           $table-bg;
$nav-tabs-link-active-border-color: $border-color;

$display1-size : 1.5rem;
$display2-size : 1.25rem;
$display3-size : 1.125rem;
$display4-size : 1rem;
$display1-weight : 400;
$display2-weight : 400;
$display3-weight : 400;
$display4-weight : 400;

@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/images';
@import '../../node_modules/bootstrap/scss/code';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/buttons';
@import '../../node_modules/bootstrap/scss/transitions';
// @import '../../node_modules/bootstrap/scss/dropdown';
@import '../../node_modules/bootstrap/scss/button-group';
@import '../../node_modules/bootstrap/scss/input-group';
@import '../../node_modules/bootstrap/scss/custom-forms';
@import '../../node_modules/bootstrap/scss/nav';
@import '../../node_modules/bootstrap/scss/navbar';
// @import '../../node_modules/bootstrap/scss/card';
// @import '../../node_modules/bootstrap/scss/breadcrumb';
@import '../../node_modules/bootstrap/scss/pagination';
@import '../../node_modules/bootstrap/scss/badge';
// @import '../../node_modules/bootstrap/scss/jumbotron';
// @import '../../node_modules/bootstrap/scss/alert';
// @import '../../node_modules/bootstrap/scss/progress';
// @import '../../node_modules/bootstrap/scss/media';
// @import '../../node_modules/bootstrap/scss/list-group';
@import '../../node_modules/bootstrap/scss/close';
// @import '../../node_modules/bootstrap/scss/modal';
// @import '../../node_modules/bootstrap/scss/tooltip';
// @import '../../node_modules/bootstrap/scss/popover';
// @import '../../node_modules/bootstrap/scss/carousel';
@import '../../node_modules/bootstrap/scss/utilities';
// @import '../../node_modules/bootstrap/scss/print';

.form-control:focus {
  outline: none;
  box-shadow: none;
}
.table-zen {
  @include mixin-zen-scrollbar();
  thead th {
    color: $table-head-color;
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    background-color: $table-head-bg;
    border-top: none;
    border-bottom: 1px solid $black;
    padding: $table-header-padding-y $content-padding-x;
    text-transform: uppercase;
    text-align: left;
  }

  thead tr:not(:first-child) th {
    font-size: $font-size-base;
    padding-top: $table-padding-y;
    padding-bottom: $table-padding-y;
  }

  tbody td {
    border-top: none;
    border-bottom: 1px solid $border-color;
  }
  tbody tr:last-child td {
    border-bottom-color: transparent
  }

  @media (min-width: map-get($grid-breakpoints, "lg")) {
    thead th,
    tbody td {
      padding-left: $content-padding-x-lg;
      padding-right: $content-padding-x-lg;
    }
  }
}

h1 {
  text-transform: uppercase;
  line-height: 0.9;

  * {
    text-transform: none;
  }
}

label {
  font-size: $font-size-md;
  font-weight: $font-weight-bold;
}

form.zen-form {
  background-color: $table-bg;
  @include mixin-content-padding();

  &.primary {
    border-top: 3px solid map-get($theme-colors, "primary");
  }
}
