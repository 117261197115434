@mixin mixin-zen-scrollbar() {
  $size: 0.85rem;
  scrollbar-color: $gray-700 $gray-900;
  scrollbar-width: $size;
  scrollbar-height: $size;
  
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }
  
  &::-webkit-scrollbar-track {
      background-color: $gray-900;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: $gray-700;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }
}