@import 'variables';

section {
  margin-bottom: $section-gap;
  position: relative;
}

textarea {
  min-height: 150px;
}

.no-text-transform {
  text-transform: initial !important;
}

.break-word {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
}

.font-size-sm {
  font-size: $font-size-sm;
}
.font-size-md {
  font-size: $font-size-md;
}
.font-size-lg {
  font-size: $font-size-lg;
}
pre {
  font-size: $font-size-base;
}
.zen-spin {
  animation: fa-spin 1s infinite linear;
}

/* remove clear button from chrome and IE */
input[type='search'] {
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
}
input[type='search'] {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
    width: 0;
    height: 0;
  }
}
