@import 'src/style/variables';

.Footer {
  color: $gray;
  padding-top: 3rem;
  padding-bottom: 5rem;
  .navbar-brand {
    margin-right: 1rem;
    padding: 0;
  }
  img.logo {
    height: 40px;
    width: auto;
  }
  .row-logo {
    padding-bottom: 3rem;
  }
  .FooterLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
      text-align: center;
      color: inherit;

      &:hover {
        color: $white;
      }
    }

    li:last-child a {
      padding-bottom: 0;
    }
  }
  .separator {
    border-style: solid;
    border-width: 0;
    border-bottom-width: 1px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    @include mixin-content-padding-x();
  }
  .FooterSocialContact {
    align-items: center;

    a {
      color: $white;

      &:hover {
        color: $blue-soft;
      }
    }
    @include mixin-content-padding-x();
  }
  .FooterSocial a {
    font-size: 1rem;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
  }
  @media (min-width: map-get($grid-breakpoints, "md")) {
    .FooterLinks {
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;

      a {
        text-align: left;
      }
    }
    .navbar-brand {
      margin-right: 2rem;
    }
  }
  @media (min-width: map-get($grid-breakpoints, "lg")) {
    .separator {
      border-bottom-width: 0;
      border-right-width: 1px;
      padding-bottom: 0;
      margin-bottom: 0;
      
    }
    .FooterLinks {
      justify-content: flex-start;
      > div {
        padding-right: $grid-gutter-width;
      }
      > div:first-child {
        padding-left: 0;

        .nav-link {
          padding-left: 0;
        }
      }
    }
    .FooterSocialContact {
      align-items: flex-end;

      li:last-child .nav-link {
        padding-right: 0;
      }
    }
    .FooterSocial a {
      padding-left: 0;
      padding-right: $grid-gutter-width;

    }
  }
}